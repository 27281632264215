import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/resume',
    },
    {
        path: '/resume',
        name: 'resume',
        // Route level code-splitting
        component: () => import('../views/ResumeView.vue'),
        meta: {
            title: 'Jeremy A Roy Resume'
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // Stolen from https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    next();
});

export default router
